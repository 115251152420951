// There are 6 slides on the mobile version of the app
// You can find that the first slide and the last two slides are different.
// Slides 2, 3, 4 called "regular-slide", last two slides (5, 6) called "enterprise-slide"

const mobileVersion = document.getElementById('mobile');

const indicators = mobileVersion.querySelectorAll('.indicator-button');
const snapScrollContainer = mobileVersion.getElementsByTagName('main')[0];
const slides = mobileVersion.getElementsByTagName('article');

indicators.forEach((indicator, i) => {
  indicator.addEventListener('click', (e) => {
    e.preventDefault();
    e.stopPropagation();
    selectSlide(i);
    let scrollOffset = 0;

    for (let j = 0; j < i; j++) {
      scrollOffset += slides[j].scrollHeight;
    }

    smoothScroll(snapScrollContainer, scrollOffset);
  });
});

snapScrollContainer.addEventListener('scroll', () => {
  let index = (snapScrollContainer.scrollTop / snapScrollContainer.scrollHeight) * slides.length;
  selectSlide(Math.floor(index));
});

const selectSlide = (index) => {
  const slideNumber = index + 1;
  indicators.forEach((indicator, i) => {
    indicator.setAttribute('aria-pressed', i === index);
  });

  const isRegularSlide = [2, 3, 4].includes(slideNumber);
  const isEnterpriseSlide = [5, 6].includes(slideNumber);

  mobileVersion.classList.toggle('regular-slide', isRegularSlide);
  mobileVersion.classList.toggle('enterprise-slide', isEnterpriseSlide);
  mobileVersion.getElementsByTagName('footer')[0].classList.toggle('regular-slide', isRegularSlide);
  mobileVersion
    .getElementsByTagName('footer')[0]
    .classList.toggle('enterprise-slide', isEnterpriseSlide);
};

const smoothScroll = (node, destination) =>
  node.scrollTo({
    top: destination,
    behavior: 'smooth',
  });
