const desktopVersion = document.getElementById('desktop');
const header = desktopVersion.querySelector('header');
const screenshotPlaceholder = desktopVersion.querySelector('.screenshot-placeholder');
const screenshots = screenshotPlaceholder.querySelectorAll('img');
const divider = desktopVersion.querySelector('.divider');

const indexToScreenshotMap = {
  0: screenshots[0],
  1: screenshots[1],
  2: screenshots[2],
  3: screenshots[3],
};

const screenshotHeight = screenshotPlaceholder.querySelector('img').scrollHeight;
const articleHeight = desktopVersion.querySelector('.regular').querySelector('article')
  .scrollHeight;
const headerMargin = +window.getComputedStyle(header).marginBottom.split('px')[0];
const enterpriseY = divider.offsetTop;

let prevShowingIndex = 0;
let prevShowingScreenshot = indexToScreenshotMap[0];
let prevScrollTop = window.pageYOffset;
const checkScrollAndReplaceScreenshot = async () => {
  const scrollDirection = window.pageYOffset > prevScrollTop ? 'DOWN' : 'UP';
  prevScrollTop = window.pageYOffset;

  const scrollDirectionCorrection =
    ((scrollDirection === 'DOWN' ? 1 : -1) * screenshots[0].scrollHeight) / 8;

  let showingIndex = Math.floor(
    (window.scrollY +
      screenshotHeight +
      scrollDirectionCorrection -
      headerMargin -
      window.innerHeight / 2) /
      (articleHeight - header.scrollHeight + 0)
  );

  if (showingIndex === prevShowingIndex) {
    return;
  }
  prevShowingIndex = showingIndex;

  if (showingIndex > Object.keys(indexToScreenshotMap).length - 1) {
    showingIndex = Object.keys(indexToScreenshotMap).length - 1;
  }

  if (showingIndex < 0) {
    showingIndex = 0;
  }

  if (prevShowingScreenshot === indexToScreenshotMap[showingIndex]) {
    return;
  }
  prevShowingScreenshot = indexToScreenshotMap[showingIndex];

  screenshots.forEach((screenshot) => screenshot.classList.toggle('show', false));
  await sleep(100);
  screenshots.forEach((screenshot) =>
    screenshot.classList.toggle('show', indexToScreenshotMap[showingIndex] === screenshot)
  );
};

const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

const debounce = (func, ms) => {
  let timeout;
  return () => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func();
    }, ms);
  };
};

window.addEventListener('load', checkScrollAndReplaceScreenshot);
window.addEventListener('scroll', debounce(checkScrollAndReplaceScreenshot, 30));
window.addEventListener('scroll', () =>
  header.classList.toggle('enterprise', window.scrollY >= enterpriseY)
);
